/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { Button, Modal, Card, OverlayTrigger, Popover } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import MyDropzone from "../../../components/DropZone";
import BandDropZone from "../../../components/DropZone/BandDropZone";
import Loader from "../../../components/Loader";
import { useRecoilState } from "recoil";
import { fabricAtom, upholsteryAtom, vintageAtom, modelAtom, selectedFabricAtom, selectedFabricBandAtom, uploadImageAtom, tabAtom, currentCustomizationAtom, selectedEdgeAtom, loaderAtom, uploadFabricAtom, addFabricBeforeAtom, showModalAtom, addNewCustomFabricAtom, selectedCustomFabricAtom, beforeSelectedImageScaletom, selectedImageScaletom, currentPillowStateAtom, pillowSideAtom, aspectRationAtom, cropperSelectionAtom, selectedAllBandAtom, selectedBandAtom, addBandFabricBeforeAtom } from "../../../state";
import { GET_URL } from "../../../constans";
import { GET_FABRICS } from "../graphQl/Query";
import { useLazyQuery } from "@apollo/client";
import { fabricCompanyId, fabricKey, fabricType } from "../../../lib/constant";
import { DebounceInput } from "react-debounce-input";

function CustomModalfilter(props: any) {
    return (
        <>
            <Modal
                show={props.visible}
                onHide={props.toggle}
                className="filter-modal section product-info "
            >
                <Modal.Header closeButton>Fabric Pattern</Modal.Header>
                <Modal.Body>
                    <div className="product-info-row">
                        <div className="right-part">
                            <div className="control-wrapper ">
                                <div className="d-flex align-items-center">
                                    <div className="form-group position-relative me-3">
                                        <input
                                            type="type"
                                            placeholder="Search Paints"
                                            className="search-control mw-88"
                                        />
                                        <img src="search.png" className="search-icon" />
                                    </div>
                                    <div className="filter-wrapper" style={{ width: "170px" }}>
                                        <p>
                                            <img src="filter-2.png" className="search-icon" /> Filter Fabric
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="item-choose w-100 pt-2" >
                            <div
                                className="d-flex align-items-start flex-wrap item-choose-wrapper pt-2 justify-content-center mobile-item">
                                <div className="item-choose mx-2 mb-3">
                                    <img src="camo.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        camo
                                    </p>
                                </div>
                                <div className="item-choose mx-2 mb-3">
                                    <img src="mosaic.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        mosaic
                                    </p>
                                </div>
                                <div className="item-choose mx-2 mb-3 active">
                                    <img src="floral grey.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        Floral Grey <br />
                                        watercolor
                                    </p>
                                </div>
                                <div className="item-choose mx-2 mb-3">
                                    <img src="tropical.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        Tropical <br /> leaves
                                    </p>
                                </div>
                                <div className="item-choose mx-2 mb-3">
                                    <img src="peach.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        Peach <br /> Flower
                                    </p>
                                </div>
                                <div className="item-choose mx-2 mb-3">
                                    <img src="doodle.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        Doodle
                                    </p>
                                </div>
                                <div className="item-choose mx-2 mb-3">
                                    <img src="blue pattern.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        Blue Pattern
                                    </p>
                                </div>
                                <div className="item-choose mx-2 mb-3">
                                    <img src="black floral.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        Black Floral
                                    </p>
                                </div>
                                <div className="item-choose mx-2 mb-3">
                                    <img src="camo.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        camo
                                    </p>
                                </div>
                                <div className="item-choose mx-2 mb-3">
                                    <img src="mosaic.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        mosaic
                                    </p>
                                </div>
                                <div className="item-choose mx-2 mb-3">
                                    <img src="floral grey.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        Floral Grey <br />
                                        watercolor
                                    </p>
                                </div>
                                <div className="item-choose mx-2 mb-3">
                                    <img src="tropical.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        Tropical <br /> leaves
                                    </p>
                                </div>
                                <div className="item-choose mx-2 mb-3">
                                    <img src="peach.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        Peach <br /> Flower
                                    </p>
                                </div>
                                <div className="item-choose mx-2 mb-3">
                                    <img src="doodle.png" className="img-fluid" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        Doodle
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {props.modalBody} */}
                </Modal.Body>
            </Modal>
        </>
    );
}

function FabricBox({ header, item, setSelectedFabric, pillowSide }: { pillowSide: string, header: string, item: any, setSelectedFabric: Function }) {
    const [fabricAtomState, setFabricsAtomState] = useRecoilState<{ partWiseFabric: any, currentPart: any }>(fabricAtom);
    const [vintageAtomState, setVintageAtomState] = useRecoilState<{ handle: string; code: string; sortName: string; paint: string; image: string; finish: string; name: string; partWiseFabric: any, currentPart: string[] }>(vintageAtom);
    const [upholsteryAtomState, setUpholsteryAtomState] = useRecoilState<{ name: string; partWiseFabric: any, currentPart: string[] }>(upholsteryAtom);
    const [selectEdge, setSelectEdge] = useRecoilState(selectedEdgeAtom)
    const [currentCustomization, setCurrentCustomization] = useRecoilState<any>(currentCustomizationAtom);
    const [uploadImage, setUploadImage] = useRecoilState<any>(uploadImageAtom)
    const getCheckSupport = localStorage.getItem('checkSupport')
    const base64Image = item.finish?.search('data:image') !== -1 ? true : false
    const [inputSetValue, setInputSetValue] = useRecoilState<any>(beforeSelectedImageScaletom);
    const [selectedCustomFabric, setSelectedCustomFabricAtom] = useRecoilState(selectedCustomFabricAtom)
    const [inputValue, setInputValue] = useRecoilState(selectedImageScaletom);
    const [aspectRation, setAspectRation] = useState(aspectRationAtom)
    const [cropperData, setCropperData] = useRecoilState(cropperSelectionAtom)
    const [selectedBand, setSelectedBand] = useRecoilState(selectedBandAtom)
    const [addBandFabricBefore, setAddBandFabricBefore] = useRecoilState(addBandFabricBeforeAtom);
    const modelViewer = document.querySelector("#model-viewer") as any;
    const [showPopover, setShowPopover] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handlePopoverOpen = () => setShowPopover(true);
    const handlePopoverClose = () => setShowPopover(false);
    const handleModalOpen = () => {
        setShowModal(true);
        handlePopoverClose(); // Close the popover when opening the modal
    };
    const handleModalClose = () => setShowModal(false);

    let selectedFabric;

    if (["BORDER", 'FABRIC'].includes(header))
        selectedFabric = fabricAtomState?.partWiseFabric[fabricAtomState.currentPart[0]];
    else if (["PAINT"].includes(header))
        selectedFabric = vintageAtomState.paint;
    else if (["UPHOLSTERY"].includes(header))
        selectedFabric = upholsteryAtomState.partWiseFabric[upholsteryAtomState.currentPart[0]];

    const checkLog = base64Image ? (selectedBand?.id?.trim() === item?.id?.trim() ? "active" : inputSetValue[pillowSide]?.id === item.id ? 'active' : '') : item._id === selectedFabric?._id ? "active" : "";


    const popover = (
        <Popover id="popover-image-card" style={{ border: "none", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', cursor: 'pointer' }}>
            <Popover.Body style={{ padding: 0 }} onClick={handleModalOpen}>
                <Card style={{ width: '14rem' }}>
                    <Card.Img
                        variant="top"
                        src={
                            base64Image
                                ? item.finish
                                : GET_URL(
                                    getCheckSupport && window?.webpSupport
                                        ? item.finish?.replaceAll('.jpg', '.webp')
                                        : item.finish
                                )
                        }
                        alt={
                            item.name
                                ? item.name
                                : header === "BORDER"
                                    ? 'Custom Band'
                                    : 'Custom Fabric'
                        }
                        style={{
                            height: '220px',
                            objectFit: 'contain',
                        }}
                    />
                    <Card.Body style={{ textAlign: 'center', paddingBottom: '15px', paddingTop: '0' }}>
                        <Card.Title style={{ fontSize: '0.75rem', margin: '0' }}>
                            {item.name
                                ? item.name
                                : header === "BORDER"
                                    ? 'Custom Band'
                                    : 'Custom Fabric'}
                        </Card.Title>
                    </Card.Body>
                </Card>
            </Popover.Body>
        </Popover>
    );
    const FinishDetails = () => {
        const finshName = item.name ? item.name : header === "BORDER" ? 'Custom Band' : 'Custom Fabric'
        const vendor = item.vendor 
        const sellPrice = item.sellPrice 
        return (
            <div className='w-100 mx-3'>
                 <div className="row">

                    {item && <>
                        <div className="col-5">
                            <p className="font-bold">Name</p>
                        </div>
                        <div className="col-7">
                            <p>{finshName}</p>
                        </div>
                    </>}
                    {vendor && <>
                        <div className="col-5">
                            <p className="font-bold">Vendor</p>
                        </div>
                        <div className="col-7">
                            <p>{vendor}</p>
                        </div>
                    </>
                    }
                    {sellPrice && <>
                        <div className="col-5">
                            <p className="font-bold">Price</p>
                        </div>
                        <div className="col-7">
                            <p>$ {sellPrice}</p>
                        </div>
                    </>
                    }
                 </div>
               
            </div>
        )
    }
    return (
        <>

            <div key={item._id} className={`fabric-box item-choose me-3 mb-3 ${checkLog ? "active" : ""}
                cursor-pointer` }
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                onClick={() => {
                    console.log("item.name", item);
                    if (item?.customBand) {
                        // setSelectedFabric({})
                    } else {
                        setSelectedFabric(item)
                    }
                    if (header === 'FABRIC') {
                        // Added By Dron (start)
                        // on finish click change pillowSide
                        console.log("model", pillowSide)
                        if (pillowSide === 'Front') {
                            if (modelViewer) {
                                modelViewer.cameraOrbit = '0deg 75deg 105%';
                            }
                        } else if (pillowSide === 'Back') {
                            if (modelViewer) {
                                modelViewer.cameraOrbit = '180deg 75deg 105%';
                            }
                        }
                        // Added By Dron (end)
                        if (base64Image) {
                            if (pillowSide === 'Front') {
                                if (base64Image ? item.imgData : uploadImage['Front']) {
                                    setUploadImage({ ...uploadImage, Front: base64Image ? item.imgData : uploadImage['Front'] })
                                }
                            } else if (pillowSide === 'Back') {
                                if (base64Image ? item.imgData : uploadImage['Back']) {
                                    setUploadImage({ ...uploadImage, Back: base64Image ? item.imgData : uploadImage['Back'] })
                                }
                            } else {
                                setUploadImage({})
                            }
                        } else {
                            if (pillowSide === 'Front' && uploadImage['Front']) {
                                const { Front, ...rest } = uploadImage;
                                setUploadImage({ ...rest });
                            } else if (pillowSide === 'Back' && uploadImage['Back']) {
                                const { Back, ...rest } = uploadImage;
                                setUploadImage({ ...rest });
                            }
                            setInputSetValue({
                                ...inputSetValue,
                                [pillowSide]: {}
                            })
                        }
                        if (base64Image) {
                            setInputValue({
                                ...inputValue,
                                [pillowSide]: {
                                    "Horizontal": item?.setHorizontal || 0,
                                    "Vertical": item?.setVertical || 0,
                                    "Scale": item?.Scale || 1
                                }
                            })
                            setAspectRation(item?.aspectRation || {})
                            setCropperData(item?.cropperData || {})
                            setSelectedCustomFabricAtom(item)
                            setInputSetValue({
                                ...inputSetValue,
                                [pillowSide]: item
                            })
                        } else {
                            setSelectedCustomFabricAtom({ id: '' })
                            // if (pillowSide === 'Front' && uploadImage['Front']) {
                            //     const { Front, ...rest } = uploadImage;
                            //     setUploadImage({ ...rest });
                            // } else if (pillowSide === 'Back' && uploadImage['Back']) {
                            //     const { Back, ...rest } = uploadImage;
                            //     setUploadImage({ ...rest });
                            // }
                        }
                    }
                    if (["BORDER", 'FABRIC'].includes(header)) {
                        if (fabricAtomState?.currentPart?.length > 0) {
                            const partWiseFabric = fabricAtomState.currentPart.reduce((acc: any, part: string) => {
                                acc[part] = item
                                return acc
                            }, {})
                            const newFabric = { ...fabricAtomState, partWiseFabric: { ...fabricAtomState.partWiseFabric, ...partWiseFabric } };
                            setFabricsAtomState({
                                ...newFabric,
                            })
                            if (item?.customBand) {
                                setSelectedBand(item)
                                setAddBandFabricBefore(item?.originalImage || '')
                            } else {
                                setAddBandFabricBefore('')
                                setSelectedBand({ id: '' })
                            }
                            const borderName = currentCustomization?.border?.name;
                            const uniqueData: any = Array.from(new Set([...selectEdge, borderName]));
                            setSelectEdge(uniqueData);
                        }
                    } else if (["PAINT"].includes(header)) {
                        // setAlphaSelect({ name: '', type: "", index: 0 })
                        setVintageAtomState({
                            ...vintageAtomState,
                            paint: item,
                            partWiseFabric: (vintageAtomState?.currentPart.reduce((acc: any, part: string) => {
                                acc[part] = item
                                return acc
                            }, {}))
                        })
                    } else if (["UPHOLSTERY"].includes(header)) {
                        const partWiseFabric = upholsteryAtomState.currentPart.reduce((acc: any, part: string) => {
                            acc[part] = item
                            return acc
                        }, {})
                        const newFabric = { ...upholsteryAtomState, partWiseFabric: { ...upholsteryAtomState.partWiseFabric, ...partWiseFabric } };
                        setUpholsteryAtomState({
                            ...newFabric,
                        })
                    }
                }}
            >
                <OverlayTrigger
                    show={showPopover}
                    trigger={['hover','focus']}
                    placement="top"
                    overlay={popover}
                >

                    <img src={base64Image ? item.finish : GET_URL(getCheckSupport && window?.webpSupport ? item.finish?.replaceAll('.jpg', '.webp') : item.finish)} className="img-fluid" />
                </OverlayTrigger>

                {/* <img src={GET_URL(getCheckSupport ? item.finish?.replaceAll('.jpg', '.webp') : item.finish)} className="img-fluid" /> */}
                <p className="font-14 text-center text-black mb-0" style={{
                    width: 75, height: 42
                }}>
                    {/* {item.name.slice(0, 12) + "..."} */}
                    {item.name ? item.name : header === "BORDER" ? 'Custom Band' : 'Custom Fabric'}
                </p>
            </div>

            <Modal size="lg" show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton ></Modal.Header>
                <Modal.Body className="container-fluid" style={{width:'100%',marginBottom:"0"}}>
                <div className="product-info-row container-fluid px-5 pb-5">
                    <div className="row ">
                        <div className="col-lg-6 col-xl-6 left-part mt-rem-6 mt-0 d-flex justify-content-between flex-column p-0" >
                            <img src={base64Image ? item.diffuse : GET_URL(getCheckSupport && window?.webpSupport ? item.diffuse?.replaceAll('.jpg', '.webp') : item.diffuse)} className="img-fluid" style={{}}/>
                        </div>
                        <div className="col-lg-6 col-xl-6 right-part text-left mt-2 ">
                            {FinishDetails()}
                        </div>
                    </div>
                </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const FabricSelection = ({ header, setFabric, materialCategory, subMaterialCategory, type, pillowSide }: { pillowSide: string, header: string, type?: string, setFabric: Function, materialCategory?: any, subMaterialCategory?: any }) => {
    const initLimit = 12
    const [uploadFabricModel, setUploadFabricModel] = useRecoilState<any>(uploadFabricAtom);
    const [selectedFabricBand, setSelectedFabricBand] = useRecoilState<any>(selectedFabricBandAtom);
    const [modalfilter, setModalfilter] = useState(false);
    const [filterOn, setFilterOn] = useState(false);
    const [fabrics, setFabrics] = useState<any[]>([]);
    const [modelAtomState, setModelAtomState] = useRecoilState<any>(modelAtom);
    const [totalFabrics, setTotalFabrics] = useState<number>(0);
    const [selectedFabric, setSelectedFabric] = useRecoilState<any>(selectedFabricAtom);
    const [skip, setSkip] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [uploadImage, setUploadImage] = useRecoilState<any>(uploadImageAtom)
    const [tab, setTab] = useRecoilState<string>(tabAtom);
    const [currentCustomization, setCurrentCustomization] = useRecoilState<any>(currentCustomizationAtom);
    const showFabric = modelAtomState?.categoryName === 'upholstery' ? ['TAB_5', 'TAB_1']?.includes(tab) ? false : true : tab === 'TAB_3' && currentCustomization?.border?.name === 'No' || tab === 'TAB_5' && currentCustomization?.band?.name === 'No'
    const [fabric, setFabricsAtomState] = useRecoilState(fabricAtom);
    const [selectEdge, setSelectEdge] = useRecoilState(selectedEdgeAtom)
    const [urlData, setUrlData] = useState<string>('')
    const [vintageAtomState, setVintageAtomState] = useRecoilState<{ handle: string; code: string; paint: string; image: string; finish: string; name: string; sortName: string; partWiseFabric: any, currentPart: string[] }>(vintageAtom);
    const [addFabricBefore, setAddFabricBefore] = useRecoilState(addFabricBeforeAtom);
    const [showModal, setShowModal] = useRecoilState(showModalAtom)
    const [addNewCustomFabric, setAddNewCustomFabric] = useRecoilState(addNewCustomFabricAtom)
    const [selectedAllBand, setselectedAllBand] = useRecoilState(selectedAllBandAtom)
    // Query
    const [getFabricsQuery, { data, loading }] = useLazyQuery(GET_FABRICS, {
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        setSkip(0)
        setFabrics([])
    }, [subMaterialCategory])

    useEffect(() => {
        if (search?.length || skip !== 0) {
            getFabricsQuery({
                variables: {
                    "input": {
                        "limit": initLimit,
                        "skip": skip,
                        "type": fabricType,
                        "key": fabricKey,
                        "companyId": fabricCompanyId,
                        ...(search !== "" && { name: search }),
                        meterialId: subMaterialCategory,
                        parentMaterialId: materialCategory
                    }
                }
            })
        } else {
            getFabricsQuery({
                variables: {
                    "input": {
                        "limit": initLimit,
                        "skip": 0,
                        "type": fabricType,
                        "key": fabricKey,
                        "companyId": fabricCompanyId,
                        ...(search !== "" && { name: search }),
                        meterialId: subMaterialCategory,
                        parentMaterialId: materialCategory
                    }
                }
            })
            setSkip(0)
        }

    }, [skip, search, subMaterialCategory, materialCategory])

    useEffect(() => {
        if (data?.getFabrics) {
            if (skip === 0) {
                if (header === 'PAINT') {
                    if (!vintageAtomState?.paint) {
                        setVintageAtomState({
                            ...vintageAtomState,
                            paint: data?.getFabrics?.finishes?.[0],
                            partWiseFabric: (vintageAtomState?.currentPart.reduce((acc: any, part: string) => {
                                acc[part] = data?.getFabrics?.finishes?.[0]
                                return acc
                            }, {}))
                        })
                    }
                    setSelectedFabric(data?.getFabrics?.finishes?.[0])
                }
                setFabrics([...data?.getFabrics?.finishes])
            } else {
                setFabrics([...fabrics, ...data?.getFabrics?.finishes])
            }
            setTotalFabrics(data?.getFabrics?.totalFinishes || 0)
        }
    }, [data])

    // useEffect(() => {
    //     const updatePartName: any = []
    //     const currentPartValue = fabric.currentPart;
    //     const partWiseFabricObject = fabric.partWiseFabric;
    //     const borderCurrentName: any = currentCustomization?.border?.name
    //     for (var i = 0; i < currentPartValue.length; i++) {
    //         var currentPart = currentPartValue[i];
    //         if (!(currentPart in partWiseFabricObject)) {
    //             updatePartName.push(currentPart)
    //         }
    //     }
    //     const checkData = !selectEdge.some(obj => obj === borderCurrentName);
    //     if (header === 'BORDER' && tab === 'TAB_3' && currentCustomization?.border?.name !== 'No' && fabrics?.length > 0 && checkData) {
    //         // if (updatePartName?.length > 0) {
    //         if (fabric?.currentPart?.length > 0) {
    //             const partWiseFabric = fabric?.currentPart.reduce((acc: any, part: string) => {
    //                 // const partWiseFabric = updatePartName.reduce((acc: any, part: string) => {
    //                 acc[part] = fabrics?.[0]
    //                 return acc
    //             }, {})
    //             const newFabric = { ...fabric, partWiseFabric: { ...fabric?.partWiseFabric, ...partWiseFabric } };
    //             setFabricsAtomState({
    //                 ...newFabric,
    //             })
    //         }
    //     } else if (header === 'BORDER' && tab === 'TAB_5' && currentCustomization?.band?.name !== 'No' && fabrics?.length > 0) {
    //         if (updatePartName?.length > 0) {
    //             const partWiseFabric = updatePartName.reduce((acc: any, part: string) => {
    //                 acc[part] = fabrics?.[0]
    //                 return acc
    //             }, {})
    //             const newFabric = { ...fabric, partWiseFabric: { ...fabric?.partWiseFabric, ...partWiseFabric } };
    //             setFabricsAtomState({
    //                 ...newFabric,
    //             })
    //         }
    //     }
    // }, [currentCustomization, fabrics])

    useEffect(() => {
        if (selectedFabric)
            setFabric(GET_URL(selectedFabric.diffuseOriginal));
    }, [selectedFabric]);

    if (!uploadFabricModel) {
        const fabricAllData = header === "FABRIC" ? [...addNewCustomFabric, ...fabrics] : tab === "TAB_5" ? [...selectedAllBand, ...fabrics] : fabrics
        return (
            <>
                <div className={header === "UPHOLSTERY" ? 'pt-4' : tab !== "TAB_3" ? !["PAINT"].includes(header) ? 'p-4' : '' : ''}>
                    <div className="control-wrapper fabric-block">
                        {window.screen.availWidth > 944 && !showFabric && (
                            <div
                                className="d-flex align-items-center fabric-input-wrapper w-100"
                            >
                                <div className={`form-group position-relative me-3 `}
                                    style={{ maxWidth: '100%' }}
                                >
                                    <DebounceInput
                                        className="search-control maxw-100 w-100 "
                                        placeholder={header === "UPHOLSTERY" ? "Search Wood" : header === 'PAINT' ? "Search Paints" : "Search Fabric"}
                                        autoComplete="off"
                                        value={search}
                                        debounceTimeout={500}
                                        autoFocus={true}
                                        // style={{ padding: "17px 0px 17px 33px" }}
                                        onChange={(e) => {
                                            setSearch(e.target.value)
                                            setTotalFabrics(0)
                                            setFabrics([])
                                            setSkip(0)
                                        }}
                                    />
                                    <img src="search.png" className="search-icon" />
                                </div>
                            </div>
                        )}

                        {!showFabric && <div className="item-choose w-100 pt-2">
                            <div id="scrollableDiv" >
                                <InfiniteScroll
                                    className="d-flex align-items-start flex-wrap item-choose-wrapper pt-2 maxw-100 w-100"
                                    style={{ height: 300, overflow: "auto" }}
                                    dataLength={fabrics?.length}
                                    height={400}
                                    next={() => {
                                        if (fabrics?.length < totalFabrics) {
                                            setSkip(fabrics?.length)
                                        }
                                    }}
                                    hasMore={true}
                                    loader={
                                        fabrics?.length < totalFabrics && <div className="loader" key={0}>
                                            <Loader />
                                        </div>
                                    }
                                    scrollableTarget="scrollableDiv"
                                >
                                    {type !== 'vintage' && ['TAB_5', 'TAB_2'].includes(tab) && <div className="fabric-box item-choose me-3 mb-3 cursor-pointer" onClick={() => {
                                        if (header === "FABRIC") {
                                            setSelectedFabricBand('FABRIC')
                                        } else {
                                            setSelectedFabricBand('BORDER')
                                        }
                                        setUploadFabricModel(true)
                                    }}>
                                        <img src="Group 1783.png" className="img-fluid add-fabric-button" />
                                        <p className="font-14 text-center text-black mb-0 mt-2">
                                            {header === "FABRIC" ? 'Add Fabric' : 'Add Band'}
                                        </p>
                                    </div>
                                    }
                                    {!fabrics?.length && loading && <Loader />}
                                    {fabricAllData?.map((item, index) => (
                                        // {fabrics?.map((item, index) => (
                                        <FabricBox
                                            pillowSide={pillowSide}
                                            key={index}
                                            item={item}
                                            setSelectedFabric={(data: any) => {
                                                setSelectedFabric(data)
                                            }}
                                            header={header}
                                        />
                                    ))}
                                </InfiniteScroll>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <CustomModalfilter
                    btnName="Name"
                    modalBody="THIS IS CUSTOM MODAL"
                    closeBtnName="CLOSE"
                    okBtnName="OK"
                    modalHeading="THIS IS HEADING!!"
                    toggle={() => setModalfilter(!modalfilter)}
                    visible={modalfilter}
                />
            </>
        );
    }
    else if (selectedFabricBand === 'BORDER') {
        return (
            <div>
                <div className="d-flex justify-content-between px-4 pt-4" >
                    <h4 className="font-17">UPLOAD IMAGE</h4>
                    <div className="cursor-pointer" onClick={() => {
                        setSelectedFabricBand('')
                        setUploadFabricModel(false)
                    }}>X</div>
                </div>
                <div className="p-4">
                    <BandDropZone />
                    {/* <div className="separator my-5">OR</div> */}
                    {/* <label className="mb-2">URL</label>
                    <div className="d-flex">
                        <input className="reset-input-style me-2 w-100" type="url" onChange={(e) => setUrlData(e.target.value)} placeholder="https://suitepieces.poly9ai" />
                        <Button className="w-min-126px" variant="primary" type="submit" onClick={() => {
                            if (urlData?.length) {
                                setAddFabricBefore({ ...uploadImage, [fabric?.currentPart?.[0]]: urlData })
                                setShowModal(true)
                                setSelectedFabricBand('')
                                setUploadFabricModel(false)
                            }
                        }}>
                            Upload
                        </Button>
                    </div> */}
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <div className="d-flex justify-content-between px-4 pt-4" >
                    <h4 className="font-17">UPLOAD IMAGE</h4>
                    <div className="cursor-pointer" onClick={() => setUploadFabricModel(false)}>X</div>
                </div>
                <div className="p-4">
                    <MyDropzone />
                    <div className="separator my-5">OR</div>
                    <label className="mb-2">URL</label>
                    <div className="d-flex">
                        <input className="reset-input-style me-2 w-100" type="url" onChange={(e) => setUrlData(e.target.value)} placeholder="https://suitepieces.poly9ai" />
                        <Button className="w-min-126px" variant="primary" type="submit" onClick={() => {
                            if (urlData?.length) {
                                setAddFabricBefore({ ...uploadImage, [fabric?.currentPart?.[0]]: urlData })
                                setShowModal(true)
                                setUploadFabricModel(false)
                            }
                        }}>
                            Upload
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

}

export default FabricSelection;